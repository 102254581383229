require("../scss/header.scss");


var header = function(){
	var toggleBtn = document.getElementById("mobileToggle");
	var mobileMenuBreakpoint = 769;
	var isMobileMenuOpen = false;

	constructor = function(){
		$(toggleBtn).click(toggleMobileMenu);
	}


	//PUBLIC METHODS
	
	resizeWin = function(viewportSize){
		if(viewportSize.width >= mobileMenuBreakpoint && isMobileMenuOpen){
			closeMobileMenu();
		}
	}

	toggleMobileMenu = function(){
		if(isMobileMenuOpen){
			closeMobileMenu();
		}else{
			openMobileMenu();
		}
		return false;
	}

	closeMobileMenu = function(){
		$("header").removeClass("mobileMenuOpen");
		TweenMax.to(toggleBtn, 0.3, {rotation:0});
		isMobileMenuOpen = false;
	}

	openMobileMenu = function(){
		$("header").addClass("mobileMenuOpen");
		TweenMax.to(toggleBtn, 0.3, {rotation:-180});
		isMobileMenuOpen = true;
	}

	$(document).ready(constructor);

	return {
		resize: resizeWin,
		isMOpen: isMobileMenuOpen,
		toggleMMenu: toggleMobileMenu,
		closeMMenu: closeMobileMenu,
		openMMenu: openMobileMenu
	}
}

module.exports = header();