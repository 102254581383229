require("../scss/footer.scss");


var footer = function(){
	
	var currOpenTdata = null;
	var onMobile = false;

	constructor = function(){
		$('.firstContainer .eqCols').matchHeight();
		$('.secondContainer .eqCols').matchHeight();
	}

	resizeWin = function(viewportSize){
		if(viewportSize.width <= 639 && !onMobile){
			onMobile = true;
			$(".dropBtn").click(function(){
				openDropdown($(this).data("did"));
				return false;
			});
			openDropdown("null");
		}else if(viewportSize.width > 639 && onMobile){
			onMobile = false;
			$(".dropBtn").unbind("click");
			TweenMax.set($("footer .dropContent"), {height:"auto"});
		}

		if($("body").height() < viewportSize.height && viewportSize.width > 639){
			$("footer").addClass("fixed");
		}else{
			$("footer").removeClass("fixed");
		}
	}

	openDropdown = function(tdata){
		//if toggle
		if(tdata == currOpenTdata && tdata != "null"){
			TweenMax.to($("footer .dropContent[data-did='"+tdata+"']"), 0.3, {height:0});
			TweenMax.to($("footer .dropBtn[data-did='"+tdata+"'] .dropdown"), 0.3, {rotation:180});
			currOpenTdata = null;
			$("window").resize();
			return false;
		}
		//else close all other dropdowns
		$("footer .dropContent").each(function(index, value){
			if($(value).data("did") != tdata){
				TweenMax.to(value, 0.3, {height:0});
			}else{
				TweenMax.set(value, {height:"auto"});
				TweenMax.from(value, 0.3, {height:0});
			}
		});
		$("footer .dropBtn").each(function(index, value){
			if($(value).data("did") != tdata){
				TweenMax.to($(value).find(".dropdown"), 0.3, {rotation:180});
			}else{
				TweenMax.to($(value).find(".dropdown"), 0.3, {rotation:0});
			}
		});
		currOpenTdata = tdata;
		$("window").resize();
		return false;
	}

	$(document).ready(constructor);

	return {
		resize: resizeWin,
	}
}

module.exports = footer();