require("../scss/reset/reset.scss");
require("../scss/bootstrap/customBootstrap.scss");
require("../scss/styles.scss");
import imagesLoaded from 'imagesLoaded';

import ScrollReveal from 'scrollreveal';
import MatchHeight from 'jquery-match-height';
import headsUp from 'headsup.js'


//local imports
import Header from './header.js';
import Footer from './footer.js';


window.APP = {};
APP.getWinVisibleSize = function() {
    var WX, WY;
    if (window.innerWidth) {
        WX = window.innerWidth;
        WY = window.innerHeight;
    } else {
        WX = document.body.clientWidth;
        WY = document.body.clientHeight;
    }
    return {width:WX, height:WY};
}

APP.scrollToElem = function(selector, callback, customtt) {
    var tt = 1.2;
    if(customtt) tt = customtt;
    var ttop = $(selector).offset().top;
    ttop-=$("header").outerHeight();
    TweenMax.to(window, tt, {
        scrollTo: {
            y: ttop
        },
        ease: Power2.easeOut,
        onComplete:function(){
            if(callback) callback();
        }
    });
}

/*
HASH NAVIGATION WITH HISTORY STATE PUSH
 */
//Set hash / history state
APP.setHash = function(hash){
    if(APP.getHash() == hash){
        return;
    }
    if(history.pushState) {
        var currUrl = location.protocol+'//'+location.host+location.pathname;
        if(APP.getHash() == null){
            history.replaceState("", "", currUrl+"#"+hash);
        }else{
            history.pushState("", "", currUrl+"#"+hash);
        }
        
    }
    else {
        window.location.hash = hash;
    }
}
//get current hash
APP.getHash = function(){
    var hash = window.location.hash;
    if(hash == ""){
        hash = null;
    }else{
        hash = hash.substring(1);
    }
    return hash;
}
//add hashchange listeners for navigation
APP.addHashChangeCallback = function(callback){
    if(window.addEventListener) {
        window.addEventListener("hashchange", callback, false);
    }
    else if (window.attachEvent) {
        window.attachEvent("onhashchange", callback);
    }
}


//////// SETUP SCROLL REVEAL
function initScrollReveal(){
	ScrollReveal({
		viewFactor: 0.1,
		duration: 1000,
		interval: 1000
	});

	var fade = {
		distance: "10%",
		duration: 1000
	}

	var fadeTop = {
		distance: "10%",
		origin: "top",
		duration: 1000
	}

	var fadeLeft = {
		distance: "10%",
		origin: "left",
		duration: 1000
	}

	var fadeRight = {
		distance: "10%",
		origin: "right",
		duration: 1000
	}

	var textAndFade = {
		distance: "10%",
		beforeReveal: function(elem){
			$(elem).find("p, h1, h2, h3, h4, h5, h6, label").each(function(index, value){
				var innerh = decodeURI(value.getAttribute("data-reveal-orig"));
				var obj = {
					v: innerh,
					l: innerh.length
				}
				var tt = Math.min(innerh.length*0.1, 1.6);
				TweenMax.from(obj, tt, {l:0, onUpdate:function(){
					value.innerHTML = obj.v.substr(0, Math.round(obj.l));
				}, delay: index*0.2})

			})
		}
	}

	var text = {
		distance: "0",
		opacity: 1,
		beforeReveal: function(elem){
			$(elem).find("p, h1, h2, h3, h4, h5, h6, label").each(function(index, value){
				var innerh = decodeURI(value.getAttribute("data-reveal-orig"));
				var obj = {
					v: innerh,
					l: innerh.length
				}
				var tt = Math.min(innerh.length*0.1, 1.6);
				TweenMax.from(obj, tt, {l:0, onUpdate:function(){
					value.innerHTML = obj.v.substr(0, Math.round(obj.l));
				}, delay: index*0.2})

			})
		}
	}

	//prepare all text transitions
	$("*[data-reveal='text'], *[data-reveal='textAndFade']").find("p, h1, h2, h3, h4, h5, h6, label").each(function(index, value){
		value.setAttribute("data-reveal-orig", encodeURI(value.innerHTML));
		$(value).css({"min-height":$(value).height()});
		value.innerHTML = "&#8203;";
	})


	//standard transitions
	ScrollReveal().reveal("*[data-reveal='fade']", fade);
	ScrollReveal().reveal("*[data-reveal='fadeLeft']", fadeLeft);
	ScrollReveal().reveal("*[data-reveal='fadeRight']", fadeRight);
	ScrollReveal().reveal("*[data-reveal='text']", text);

	//relacionados list
	ScrollReveal().reveal("#boxDisplay.related .col-md-2:nth-child(even)", fade);
	ScrollReveal().reveal("#boxDisplay.related .col-md-2:nth-child(odd)", fadeTop);

}



//////// WIN RESIZE
$(window).resize(function(){
	var wz = APP.getWinVisibleSize();
	Header.resize(wz);
	Footer.resize(wz);
});

//////// MAIN DOC READY
$(document).ready(function(){
	$(window).resize();
	initScrollReveal();
	headsUp({
	  duration: 0.3,
	  easing: 'ease',
	  delay: 0,
	  debounce: 100
	});
})


imagesLoaded(document.querySelector('body'), function(){
	var rnd = Math.round(Math.random()*4);
	switch(rnd){
		case 0:
		TweenMax.to("#preloadBack", 1, {left:"-100vw", delay: 0});
		break;
		case 1:
		TweenMax.to("#preloadBack", 1, {left:"100vw", delay: 0});
		break;
		case 2:
		TweenMax.to("#preloadBack", 1, {top:"100vh", delay: 0});
		default:
		TweenMax.to("#preloadBack", 1, {top:"-100vh", delay: 0});
	}
	TweenMax.to("#preload", 0.3, {scale:1.3, autoAlpha:0, delay: 0.2});
	TweenMax.to("#preloadScreen", 0.3, {autoAlpha:0, display: "none", delay: 0.6});
})